import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import OrderPage from 'rev.sdk.js/Templates/OrderPage';
import renderCustomCartCol from '../../Utils/renderCustomCartCol';
import {Copy} from '@styled-icons/boxicons-regular/Copy';
import * as ClipboardUtil from '../../Utils/ClipboardUtil';

function OrderList(props) {
  const [store] = useOutlet('store');

  return (
    <Wrapper>
      <OrderPage
        {...props}
        fetchFromCheckout={false}
        renderCustomComponent={renderCustomCartCol}
        renderExtraPaymentInfo={(order) => {
          if (order.payment_subtype === 'offline') {
            return (
              <div className="extra-payment-info">
                <div>
                  收款帳號:{' '}
                  <span id="store-bank-account">
                    {store?.config?.store_bank_account || '---'}
                  </span>
                  <div
                    className="copy-icon"
                    onClick={() =>
                      ClipboardUtil.copyFromElem('store-bank-account')
                    }>
                    <Copy size={20} color="black" />
                  </div>
                </div>

                <div>
                  收款銀行:{' '}
                  <span>{store?.config?.store_bank_name || '---'}</span>
                </div>

                <div>
                  收款分行:{' '}
                  <span>{store?.config?.store_bank_branch || '---'}</span>
                </div>
                <h3>
                  *
                  溫馨提醒：若您的訂單選擇ATM，請記得於「3天內」完成匯款，並更新匯款後五碼資訊以便核對，避免訂單自動被取消。
                </h3>
              </div>
            );
          }
          return null;
        }}
        renderExtraLogisticsInfo={(order) => {
          const selfPickAddr = store?.config?.store_self_pick_address;
          if (selfPickAddr && order.logistics_type === 'SELF_PICK') {
            return (
              <div className="extra-payment-info">
                <h3>自取地址: {selfPickAddr}</h3>
              </div>
            );
          }
          return null;
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .extra-payment-info {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #eee;

    & > * {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 5px;

      & .copy-icon {
        margin: 5px;
        border: 1px solid black;
        border-radius: 3px;
        cursor: pointer;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
        transition: all 200ms;
        &:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;

export default OrderList;
